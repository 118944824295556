import React from 'react';
import { createRoot } from 'react-dom/client';

/**
 * A registry of the components that can be used by the `ReactComponent` component.
 * See `ReactComponents.setup` for the registering method.
 */
const componentRegistry = new Map<string, (...arg0: any[]) => JSX.Element>();
/**
 * This is a temporal "store" of elements to render. `ReactComponent` can try to render a component
 * before it is added to the registry. The storage allows the `ReactComponent.setup` method to render them afterwards.
 */
const pendingRender = new Map<string, ReactComponent[]>();

class ReactComponent extends HTMLElement {
  private rendered = false;

  connectedCallback() {
    const component = componentRegistry.get(this.getComponentName());

    if (component) {
      this.render();
    } else {
      this.savePendingRender();
    }
  }

  render() {
    if (this.rendered) return; // Prevents a rendered element to be rendered twice.

    const Component = componentRegistry.get(this.getComponentName());
    if (!Component) return; // This should never happen at this point.

    const props = this.getProps();

    createRoot(this).render(<Component {...props} />);
    this.rendered = true;
  }

  private getComponentName() {
    const name = this.getAttribute('component');

    // This should only happen in development. No user should see this error message.
    if (!name) throw new Error('A React Component element must be provided');

    return name;
  }

  private getProps() {
    let propsString = this.getAttribute('props') || '';
    propsString = propsString.replace(/\+/g, ' ');
    propsString = decodeURIComponent(propsString);
    return JSON.parse(propsString);
  }

  private savePendingRender() {
    const componentName = this.getComponentName();

    const renderedElementsList = pendingRender.get(componentName);
    if (!renderedElementsList) {
      pendingRender.set(componentName, [this]);
    } else {
      renderedElementsList.push(this);
    }
  }
}

customElements.define('react-component', ReactComponent);

export class ReactComponents {
  /**
   * Adds a React component to the registry. It also renders the pending elements if there was an attempt
   * to render before it was defined.
   *
   * @link https://github.com/renchap/webpacker-react/blob/174cad7a129083a1d18f20c56774d3b35b402cbc/javascript/webpacker_react-npm-module/src/index.js#L58
   */
  static setup(components: { [key: string]: (...arg0: any[]) => JSX.Element }) {
    for (const key in components) {
      const component = components[key];
      if (component) {
        componentRegistry.set(key, component);
        this.renderElements(key);
      }
    }
  }

  private static renderElements(component: string) {
    const registeredElements = pendingRender.get(component);
    if (!registeredElements) return;
    registeredElements.forEach((element) => element.render());
  }
}

window.ReactComponents = ReactComponents;

declare global {
  interface Window {
    ReactComponents: typeof ReactComponents;
  }
}
